body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

@import url("https://use.typekit.net/vvz4okr.css");

.customauto-displayData {
  display: block;
  max-width: 1920px;
  max-height: 1080px;
  border-left: 15px solid white;
  border-right: 15px solid white;

  width: 97%;
  margin: 9px auto;
  padding-left: 10px 0px;
  font-family: rift !important;
  background: #fff;
  color: #000;
}

.screen {
  zoom: 150%;
}

.customauto-mainsection {
  display: block;
  width: 100%;
  -webkit-border-radius: 11px;
  -moz-border-radius: 11px;
  border-radius: 11px;
  font-family: rift !important;
  background: #fff;
}

.customauto-topheader {
  display: inline-block;
  width: 100%;
  text-align: center;
}

.customauto-topheader h1 {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 50px;
  line-height: 24px;
  font-weight: 600;
  font-family: rift !important;
  color: #000;
}

.customauto-topheader h2 {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 30px;
  line-height: 40px;
  font-weight: 400;
  font-family: rift !important;
  color: #000;
}

.customauto-contentmainsection {
  display: block;
  width: 96%;
  margin: 7px auto;
  overflow: hidden;
}

.customauto-item-center {
  display: inline-block;
  width: 49%;
  margin-right: 1%;
  vertical-align: top;
}

.customauto-item-center:nth-child(2n + 2) {
  margin-right: 0;
}

.customauto-subsection {
  display: inline-block;
  width: 100%;
  font-family: rift !important;
}

.customauto-subsection h1 {
  display: inline-block;
  width: 100%;
  color: rgba(172, 36, 42, 1);
  margin: 0;
  padding: 0;
  font-size: 30px;
  text-align: center;
  line-height: 40px;
  font-family: rift !important;
}

.customauto-subsection ul {
  display: inline-block;
  width: 100%;
  list-style: none;
  margin: 0;
  padding: 0;
}

.customauto-subsection li {
  display: inline-block;
  width: 100%;
  margin-bottom: 20px;
  font-size: 30px;
  font-weight: 600;
  font-family: rift !important;
}

.customauto-listitems {
  display: inline-block;
  width: 100%;
}

.custom-auto-listitems-heading-left {
  display: inline;
  float: left;
  width: 20%;
  text-align: center;
  font-size: 50px;
  line-height: 9px;
  font-weight: 600;
  color: rgba(172, 36, 42, 1);
  font-family: rift !important;
}

.custom-auto-listitems-details-left {
  display: inline;
  float: left;
  width: 80%;
}

.custom-auto-listitems-details-left h3 {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 40px;
  line-height: 2px;
  font-weight: 600;
  font-family: rift !important;
  color: #000;
}

.custom-auto-listitems-details-left p {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 25px;
  font-family: rift !important;
  color: #000;
}

.custom-auto-listitems-heading-right {
  display: inline;
  float: left;
  width: 20%;
  text-align: center;
  font-size: 37px;
  line-height: 10px;
  font-weight: 600;
  color: rgba(172, 36, 42, 1);
  margin-left: 10%;
  font-family: rift !important;
}

.custom-auto-listitems-details-right {
  display: inline;
  float: left;
  width: 70%;
}

.custom-auto-listitems-details-right h3 {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 35px;
  line-height: 0px;
  font-weight: 600;
  font-family: rift !important;
  color: #000;
}

.custom-auto-listitems-details-right p {
  display: block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 20px;
  font-family: rift !important;
  color: #000;
}

.customauto-pricefooter {
  display: inline-block;
  color: black;
  width: 100%;
  /* text-align: center; */
  margin-top: 20px;
  margin-left: 15%;
}

.customauto-pricefooter h1 {
  display: inline-block;
  margin: 0;
  padding: 0;
  font-size: 75px;
  font-weight: 600;
  line-height: 75px;
  font-family: rift !important;
  text-align: center;
  margin-left: 10%;
}

.customauto-pricefooter p {
  display: inline-block;
  width: 100%;
  margin: 0;
  padding: 0;
  font-size: 32px;
  font-weight: 600;
  line-height: 34px;
  font-family: rift !important;
}


@media only screen and (max-width: 900px) {
  .customauto-item-center {
    width: 100%;
    margin-right: 0;
  }

  .customauto-displayData {
    padding-left: 23px 0px;
    width: 70%;
  }

  .custom-auto-listitems-heading-right {
    margin-left: 0%;
  }

  .mobile-price {
    display: flex;
  }

  .desktop-price {
    display: none;
  }
}

@media only screen and (min-width: 900px) {

  .description1 {
    width: 80%;
    margin-left: auto;
    margin-right: auto;
    padding-top: 12px;
  }

  body {
    justify-content: center;
    align-items: center;
  }


  .mobile-price {
    display: none;
  }

  .desktop-price {
    display: flex;
  }

}

/* box component here */

.wrapper {
  /* max-width: 1000px; */
  height: fit-content;
  /* margin-top: 10px; */
  /* margin-left: 10px; */
  background: white;
  text-align: center;
  align-items: center;
  font-family: rift !important;
  flex-direction: row;
  Display: flex;
  justify-content: space-around;
  gap: 2.5rem;
  width: 90%;
  /* max-height: 100px; */
}


.box {
  display: inline-block;
  color: black;
  vertical-align: bottom;
  text-align: center;
  margin-top: 7px;
  align-items: center;
  display: flex;
  justify-content: space-evenly;
}

.box h1 {
  display: inline-block;
  width: 100%;
  color: rgba(172, 36, 42, 1);
  margin: 0;
  padding: 0;
  font-size: 48px;
  text-align: center;
  line-height: 40px;
  font-family: rift !important;
}

.box h3 {
  display: inline-block;
  width: 100%;
  margin: 0 !important;
  padding: 0;
  font-size: 55px;
  text-align: left;
  line-height: 40px;
  font-family: rift !important;
  line-height: 1.3;
}

.box h2 {
  display: inline-block;
  margin: 0 !important;
  padding: 0;
  font-size: 26px;
  text-align: center;
  line-height: 40px;
  font-family: rift !important;
  line-height: 1.3;
  padding-top: 0;
}


@media only screen and (max-width: 930px) {
  .customauto-pricefooter {
    display: block;
    color: black;
    width: 100%;
    /* text-align: center; */
    margin-top: 0;
    margin-left: 0;
  }

  .customauto-pricefooter h1 {
    display: inline-block;
    margin: 0;
    padding: 0;
    font-size: 75px;
    font-weight: 600;
    line-height: 75px;
    font-family: rift !important;
    text-align: center;
    margin-left: 30%;
  }

  .customauto-pricefooter p {
    margin-top: 8px;
    margin-left: 10%;
  }

  .box h3 {
    display: inline-block;
    width: 100%;
    margin: 0 !important;
    padding: 0;
    font-size: 55px;
    text-align: center;
    line-height: 40px;
    font-family: rift !important;
    line-height: 1.3;
  }

  .wrapper {
    /* max-width: 1000px; */
    height: fit-content;
    /* margin-top: 10px; */
    /* margin-left: 10px; */
    background: white;
    text-align: center;
    align-items: center;
    font-family: rift !important;
    flex-direction: column;
    Display: flex;
    justify-content: space-around;
    gap: 1.5rem;
    margin-top: 20px;
  }

  .box h2 {
    display: inline-block;
    margin: 0 !important;
    padding: 0;
    font-size: 26px;
    text-align: center;
    line-height: 40px;
    font-family: rift !important;
    line-height: 1.3;
    padding-top: 10px;
  }

  .desktop-price {
    display: none;
  }

  .mobile-price {
    align-items: center;
    text-align: center;
    margin-left: 7%;
  }



}